@import 'variables';
nav#nav{
  position: fixed;
  right: 0;
  top: 10%;
  background: white;
}

.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 2;
  top: 0;
  right: -200px;
  background-color: $inactive-month-color;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

#navTab{
  font-size: 48px;
  position: fixed;
  top: -15px;
  right: 0;
  background-color: $inactive-month-color;
  transition: 0.5s;
  z-index: 1;
  border-radius: 0px 0 0 15px;
  border: 5px solid $inactive-month-color;
}

.sidenav p.biggerlogButton {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: $light-dark-red;
  display: block;
  transition: 0.3s;
  &:hover{
    background-color: $inactive-month-color-hover;
    font-color: $dark-red;
  }
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}