@import 'calendar';
@import 'nav';
@import 'variables';
@import 'week_modal';

.body{
  color: #333333;
}
.closeModal{
  float: right;
  &:hover{

  }
}
.biggerlogModal{
  border: 2px solid $dark-red;
  position: absolute; 
  inset: 40px; 
  background: $active-month-color none repeat scroll 0% 0%; 
  overflow: auto; 
  border-radius: 4px; 
  outline: currentcolor none medium; 
  padding: 20px;
  top: 40px;
  left: 80px;
  right: 80px;
  bottom: 40px;
  overflow: auto;
  outline: none;
}

.biggerlogModalOverlay{
  position: fixed; 
  background-color: rgba(71, 2, 2, 0.75);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: #470202;
  //opacity: 0.7 !important;
}

div.runnerlog-red-button{
  input, a{
    -moz-box-shadow:inset 0px 1px 0px 0px #edabab;
    -webkit-box-shadow:inset 0px 1px 0px 0px #edabab;
    box-shadow:inset 0px 1px 0px 0px #edabab;

    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, $light-dark-red), color-stop(1, #910013));
    background:-moz-linear-gradient(top, $light-dark-red 5%, #910013 100%);
    background:-webkit-linear-gradient(top, $light-dark-red 5%, #910013 100%);
    background:-o-linear-gradient(top, $light-dark-red 5%, #910013 100%);
    background:-ms-linear-gradient(top, $light-dark-red 5%, #910013 100%);
    background:linear-gradient(to bottom, $light-dark-red 5%, #910013 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='$light-dark-red', endColorstr='#910013',GradientType=0);
    background-color:$light-dark-red;
    border:1px solid #ff0000;
    color:#ffffff;
    text-shadow:0px 1px 0px #8a7474;
    cursor: pointer;
    &:hover{
      background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #910013), color-stop(1, $light-dark-red));
      background:-moz-linear-gradient(top, #910013 5%, $light-dark-red 100%);
      background:-webkit-linear-gradient(top, #910013 5%, $light-dark-red 100%);
      background:-o-linear-gradient(top, #910013 5%, $light-dark-red 100%);
      background:-ms-linear-gradient(top, #910013 5%, $light-dark-red 100%);
      background:linear-gradient(to bottom, #910013 5%, $light-dark-red 100%);
      filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#910013', endColorstr='$light-dark-red',GradientType=0);
      background-color:#910013;
    }
  }
}
div.runnerlog-orange-button{
  input, a{
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #fa8b41), color-stop(1, #c94d0a));
    background:-moz-linear-gradient(top, #fa8b41 5%, #c94d0a 100%);
    background:-webkit-linear-gradient(top, #fa8b41 5%, #c94d0a 100%);
    background:-o-linear-gradient(top, #fa8b41 5%, #c94d0a 100%);
    background:-ms-linear-gradient(top, #fa8b41 5%, #c94d0a 100%);
    background:linear-gradient(to bottom, #fa8b41 5%, #c94d0a 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#fa8b41', endColorstr='#c94d0a',GradientType=0);
    background-color:#fa8b41;
    border:1px solid #ff0000;
    color:#ffffff;
    cursor: pointer;
    &:hover{
      background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #c94d0a), color-stop(1, #fa8b41));
      background:-moz-linear-gradient(top, #c94d0a 5%, #fa8b41 100%);
      background:-webkit-linear-gradient(top, #c94d0a 5%, #fa8b41 100%);
      background:-o-linear-gradient(top, #c94d0a 5%, #fa8b41 100%);
      background:-ms-linear-gradient(top, #c94d0a 5%, #fa8b41 100%);
      background:linear-gradient(to bottom, #c94d0a 5%, #fa8b41 100%);
      filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#c94d0a', endColorstr='#fa8b41',GradientType=0);
      background-color:#c94d0a;
    }
  }
}
div.runnerlog-blue-button{
  input, a{
    -moz-box-shadow:inset 0px 1px 0px 0px #276873;
    -webkit-box-shadow:inset 0px 1px 0px 0px #276873;
    box-shadow:inset 0px 1px 0px 0px #276873;
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #5bc0de), color-stop(1, #408c99));
    background:-moz-linear-gradient(top, #5bc0de 5%, #408c99 100%);
    background:-webkit-linear-gradient(top, #5bc0de 5%, #408c99 100%);
    background:-o-linear-gradient(top, #5bc0de 5%, #408c99 100%);
    background:-ms-linear-gradient(top, #5bc0de 5%, #408c99 100%);
    background:linear-gradient(to bottom, #5bc0de 5%, #408c99 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#5bc0de', endColorstr='#408c99',GradientType=0);
    background-color:#5bc0de;
    border:1px solid #29668f;
    color:#ffffff;
    text-shadow:0px 1px 0px #3d768a;
    cursor: pointer;
    &:hover{
      background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #408c99), color-stop(1, #5bc0de));
      background:-moz-linear-gradient(top, #408c99 5%, #5bc0de 100%);
      background:-webkit-linear-gradient(top, #408c99 5%, #5bc0de 100%);
      background:-o-linear-gradient(top, #408c99 5%, #5bc0de 100%);
      background:-ms-linear-gradient(top, #408c99 5%, #5bc0de 100%);
      background:linear-gradient(to bottom, #408c99 5%, #5bc0de 100%);
      filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#408c99', endColorstr='#5bc0de',GradientType=0);
      background-color:#408c99;
    }
  }
}
.biggerlogButton {
  text-decoration: none;
  color: $light-dark-red;
  cursor: pointer;
  &:hover{
    //background-color: $inactive-month-color-hover;
    color: $dark-red;
  }

  &.colored{
    background-color: $inactive-month-color;
    padding: 5px;
    border-radius: 3px;
    &:hover{
      background-color: $inactive-month-color-hover;
    }
  }
}
