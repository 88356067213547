$background: #FFFFFF;
$dark-red:#910013;
$light-dark-red:#ce0826;
$lighter-dark-red:#e01836;
$very-light-red:#FFEAEA;

$active-month-color: rgba(240, 240, 240, 1);
$active-month-color-hover: rgba(221, 219, 219, 1);

$inactive-month-color: rgba(192, 192, 192, 1); // 173 171 171
$inactive-month-color-hover: #adabab;

$calender-date-color: #1B3E9B;
$border-color: #7c6666;
$top-right-blue:#A0B7C9;

$calendar-border:black;