@import 'variables';

td.activity{
  //display: inline-block;
  height: 100%;
  max-height: 100%;
}


tr.table_row{
  cursor: pointer;
  //box-shadow: 5px 10px #888888;
}

// td.DayModal{
//   vertical-align: top;
//   border-right: 2px solid black;
//   cursor: pointer;
//   &:hover{
//     box-shadow: 2px 4px #888888;
//   }
//   td.LogModal{
//     border-radius: 6px;
//   }
// }

#calendar-wrapper table thead th .text {
  position:absolute;   
  top:-20px;
  z-index:2;
  height:20px;
  width:35%;
  border:1px solid red;
}

//log form
div.log-day-tab{
  display: inline-block !important;
}
.nav-tabs#activity-tabs{
  display: inline-block;
}

.nav-tabs > li  {
    display: inline;
    color: $dark-red;
    background-color: #f2d0d0;//#FFEAEA;
    //background-coloro: white;
    outline: 0;
    padding: 5px 2px 5px 10px;
    cursor: pointer;
    &.add_log{
      span{
        padding-right: 10px;
      }
    }
    span.remove-log {
      height: 100%;
      text-align: horizontal;
      padding-left: 5px;
      padding-right: 5px;
      color: black;
      font-size: 24px;
      border-radius: 15px;
      &:hover{
        color: red;
      }
    }
}

.nav-tabs > li:hover  {
  color: #CE0826;
    font-size: 16px;
    background-color: #f2e1e1;
    //border: 1px solid black;
    border-bottom: none;
}

//Active tab
.nav-tabs > li.selected  {
    color: #FFF !important;
    background-color: $dark-red !important;
    border: none;
    &:hover{
      color: #FFF;
      background-color: $dark-red;
    }
}

p.add_log{
  display: inline-block !important;
}
ul#activity-tabs{
  display: inline-block !important;
  list-style: none;
  li.activity-tab{
    a:hover, input:hover{
      //background-color: green;
    }
    input{
      color: green;
    }
    a.add_fields:hover{
      //background-color: green;
    }

    .close{
      margin: -2px 0 0 10px;
        font-size: 18px;
        margin-bottom :1px !important;
    }
  }
}