@import 'variables';

table.weekModal{
  tr.dayNames{
    text-align: horizontal;
  }
  padding-bottom: 60px;
}

p.weekChange{
  position: fixed;
  left: 46%;
  font-size: 46px;
  cursor: pointer;
  color: $light-dark-red;
  background-color: $active-month-color;
  border-radius: 5px;
  &:hover{
    color: $dark-red;
    background-color: $inactive-month-color;
  }
  &.weekUp{
    top: 0%;
  }
  &.weekDown{
    //top: 78%;
    bottom: 0%;
    
  }
}